@import "../../assets/styles/fonts";

.contact {
  height: 100vh;
}

.contact-store-image {
  max-width: 100%;
  border: 24px solid #b19347;
  border-left: 0;
  opacity: 1;
}

.contact-title {
  font: normal normal normal MADE SAONARA 2;
  font-size: 100px;
  font-family: "Made Saonara 2";
  font-weight: 100;
  letter-spacing: 9.75px;
  color: #b19347;
  text-transform: uppercase;
  opacity: 1;
  text-align: center;
  line-height: 116px;
}

.contact-openingstijden-wrapper {
  display: flex;
  flex-direction: row;

  li {
    list-style-type: none;
    text-align: left;
    font: normal normal normal 30px/37px Corbel;
    letter-spacing: 1.95px;
    color: #877b5a;
    opacity: 1;
    font-size: 25px;
    font-weight: 500;
  }
}

.contact-openingstijden-col {
  h2 {
    text-align: left;
    font: normal normal normal 46px/55px MADE SAONARA;
    letter-spacing: 2.99px;
    color: #877b5a;
    opacity: 1;
    padding-left: 2.5rem;
    font-size: 46px;
  }

  p {
    text-align: left;
    font: normal normal normal MADE SAONARA;
    letter-spacing: 2.99px;
    color: #877b5a;
    opacity: 1;
    padding-left: 2.5rem;
    font-size: 18px;
  }
}

.contact-other-col {
  text-align: left;
  font: normal normal normal;
  font-family: "Made Saonara 2";
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 2.95px;
  color: #d5cbb3;
  opacity: 1;

  p {
    margin-bottom: 0rem;
  }

  :nth-child(even) {
    color: black;
    margin-bottom: 0.75rem;
    font-family: "Corbel";
    letter-spacing: 1.3px;
    font-size: 20px;
  }

  &-info {
    margin-top: 2rem;

    p {
      letter-spacing: 1.3px;
      color: #877b5a !important;
      opacity: 1;
      margin-bottom: 0 !important;
      font-family: "Corbel";
      font-size: 20px;
    }
  }
}

.contact-title-row {
  padding-bottom: 5%;
}

@media (max-width: 991.98px) {
  .contact-openingstijden-col {
    h2 {
      text-align: center;
    }
  }

  .contact-openingstijden-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    ul{
      width: 50%;
    }
  }

  .contact-other-col {
    text-align: center;
  }

  .contact-store-image-wrapper {
    padding: 0;
  }

  .contact-credits {
    position: relative;
    z-index: 2;
  }


}
.contact-store-image {
  border-right: 0;
}
@media (max-width: 575.98px) {
  .contact-title {
    font-size: 50px;
    line-height: 50px;
  }

  .contact-openingstijden-col {
    h2 {
      font-size: 35px;
    }
  }

  .contact-openingstijden-wrapper {
    li {
      font-size: 18px;
    }
  }
}

.contact-credits {
  letter-spacing: 1.3px;
  color: #b19347;
  opacity: 1;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 2rem;
}