@font-face {
  font-family: "corbel";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/CORBEL.TTF"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "Made Saonara 2";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/MADESAONARA2\ .otf"); /* IE9 Compat Modes */
}

.made-saonara {
  font-family: "Made Saonara 2";
  letter-spacing: 0.25rem;
  color: #b19347;
  opacity: 1;
}
