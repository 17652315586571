.images-col-1 {
  left: 8.557vw;
  text-align: right !important;
}
.about-model-first {
  border: 75px solid #fcf3ec;
  background-color: #fcf3ec;
  width: 100%;
  max-width: 100%;
}
.about-model-third {
  border: 1px solid #b19347;
  width: 80%;
  background-color: #beaea1;
}

.about-model-second {
  padding: 3rem;
  max-width: 100%;
}

.about-model-four {
  position: absolute;
  width: 50%;
  max-width: 100%;
  top: -60%;
  left: 20%;
}

.about-model-five {
  // position: absolute;
  width: 70%;
  max-width: 100%;
  border: 30px solid #f2e4db;
  opacity: 1;
  background-color: #f2e4db;
  top: -5%;
}

.about-model-six {
  position: absolute;
  width: 120%;
  max-width: 120%;
  border: 70px solid #d4bdaf;
  opacity: 1;
  background-color: #d4bdaf;
  right: 20%;
  z-index: -1;
}

@media (max-width: 991.98px) {
  .images-row {
    display: none !important;
  }
}
