@import "../../assets/styles/fonts";

.nav-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  padding-right: 2rem;
  padding-bottom: 1rem;
  font-size: 1.4rem;
  text-align: left;
  padding:3rem;
}

.nav-list {
  font-family: "Corbel";
  position: relative;
  list-style-type: none;
  // letter-spacing: 0.2rem;
  font-family: "Made Saonara 2";
  letter-spacing: .25rem;
  color: #b19347;
  opacity: 1;
}

.socials-wrapper {
  text-align: center;
  height: 1.55rem;
  // padding-right: 1rem;
  opacity: 0.3;
  

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

@media (max-width: 991px) {
  .nav-wrapper {
    right: initial;
  }
}

@media (max-width: 768px) {
  .nav-wrapper {
    top: 0;
    bottom: initial;
    // padding-bottom: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .nav-list {
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 3vw;

    a {
      padding: 1rem;
    }

    background-color: white;
    opacity: 0.3;
  }

  .socials-wrapper {
    bottom: 0;
    position: absolute;
    right: 0;
  }
}