@import "../../assets/styles/fonts";

.home-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: #bfafa2;
}
.home-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  z-index: -1;
  object-position: 70% 10%;
  width: 100vw;
  height: 100vh;
}

.logo {
  position: fixed;
  height: 12rem;
  width: auto;
  top: 0;
  left: 0;
  margin-left: 1vw;
  z-index: 1;
}

.title-wrapper {
  position: absolute;
  left: 30%;
  bottom: 45%;
  font-family: "Made Saonara 2";
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
  font-weight: 100;
  transform: scale(2);
  width: 50%;
}

.home-title-Caftans {
  position: absolute;
  top: 0;
  font-size: 100px;
  line-height: 1;
  z-index: 1;
  letter-spacing: 2.75px;
  color: #877b5a;
}

.home-title-jewels {
  position: absolute;
  font-size: 70px;
  line-height: 1;
  top: 80px;
  left: 90px;
  z-index: 1;
  letter-spacing: 8.58px;
  text-transform: uppercase;
  color: #d5cbb3;
}

.home-title-bridal {
  position: absolute;
  top: 10px;
  left: 150px;
  z-index: 1;
  text-transform: uppercase;
  color: #d5cbb3;
  letter-spacing: 2.75px;
  font-size: 22px;
  white-space: nowrap;
}

.home-title-and {
  position: absolute;
  top: -115px;
  left: 290px;
  font-size: 190px;
  color: #d5cbb3;
  opacity: 0.26;
}
.home-title-products {
  position: absolute;
  top: 150px;
  letter-spacing: 2.54px;
  color: #b19347;
  font-size: 13px;
  font-weight: 500;
  opacity: 1;
  white-space: nowrap;
}

.home-image-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
@media (max-width: 1550px) {
  .title-wrapper {
    transform: scale(1.5);
    left: 20%;
  }
}
@media (max-width: 1280px) {
  .title-wrapper {
    transform: scale(1.25);
    left: 10%;
  }
}

@media (max-width: 991.98px) {
  .home-wrapper {
    height: 100vh;
  }
  .home-image {
    opacity: 0.5;
    margin-top: 20%;
    max-height: 90%;
  }
  .logo {
    position: relative;
  }
  .title-wrapper {
    transform: scale(1);
    top: 5%;
    left: 30%;
  }
}
// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .title-wrapper {
    transform: scale(1);
    top: 5%;
    left: 25%;
  }
}
@media (max-width: 576px) {
  .title-wrapper {
    transform: scale(0.6);
    bottom: 1600px;
    left: 15%;
  }
}
@media (max-width: 576px) {
  .title-wrapper {
    transform: scale(0.6);
    bottom: 1600px;
    left: 15%;
  }
}
// .home-info {
//   font-family: "Made Saonara 2";
//   letter-spacing: 0px;
//   opacity: 1;
//   text-align: left;
//   overflow: hidden;
//   font-weight: 100;

//   span {
//     font-weight: 100;
//     letter-spacing: 2.75px;
//   }
//   .home-title-Caftans {
//     position: absolute;
//     bottom: 10.974vw;
//     left: 4.362vw;
//     font-size: 150.528px;
//     line-height: 1;
//     z-index: 1;
//     text-align: left;
//     letter-spacing: 0px;
//     color: #877b5a;
//     opacity: 1;
//   }

//   .home-title-jewels {
//     position: absolute;
//     font-size: 113.664px;
//     line-height: 1;
//     bottom: 5.115vw;
//     left: 13.672vw;
//     z-index: 1;
//     text-align: left;
//     letter-spacing: 8.58px;
//     color: #d5cbb3;
//     text-transform: uppercase;
//     opacity: 1;
//   }

//   .home-title-bridal {
//     position: absolute;
//     bottom: 16.833vw;
//     left: 18.229vw;
//     z-index: 1;
//     font-size: 38.4px;
//     text-align: left;
//     letter-spacing: 2.75px;
//     color: #d5cbb3;
//     text-transform: uppercase;
//     opacity: 1;
//   }

//   .home-title-and {
//     position: absolute;
//     bottom: 2.766vw;
//     left: 31.25vw;
//     font-size: 330.24px;
//     text-align: left;
//     letter-spacing: 0px;
//     color: #d5cbb3;
//     opacity: 0.26;
//   }
//   .home-title-products {
//     position: absolute;
//     font-size: 30.72px;
//     line-height: 1;
//     bottom: 3.813vw;
//     left: 6.836vw;
//     z-index: 1;
//     text-align: left;
//     letter-spacing: 2.54px;
//     color: #b19347;
//     opacity: 1;
//     font-weight: 300;
//   }
// }
