.custom-carousel {
  display: none;
}
.react-multi-carousel-list {
  img {
    width: 100%;
    max-width: 100%;
    padding: 3rem;
    background-color: #f2e4db;
  }
}

@media (max-width: 991.98px) {
  .custom-carousel {
    display: inline;
  }
}
