@import "../../assets/styles/fonts";

.about-wrapper {
  position: relative;
  height: 100%;
  background-color: #fcf3ec;
  text-align: center;
}

.about-content-text-wrapper {
  padding-left: 1rem;
  text-align: left;
  p {
    margin-top: 0;
    text-align: left;
    font: normal normal normal 15px/27px Corbel;
    // font-size: 16px;
    letter-spacing: 1.43px;
    color: #877b5a;
    opacity: 1;
    font-weight: 600;
    margin-bottom: 0.25rem;
    &:last-of-type {
      text-align: left;
      font: normal normal bolder Corbel;
      letter-spacing: 1.43px;
      color: #877b5a;
      opacity: 1;
      font-weight: 800;
    }
  }
}
.about-title-wrapper {
  // position: absolute;
  margin-left: auto;
  margin-right: auto;
  // left: 0;
  // right: 0;
  text-align: center;
  h1 {
    @extend .made-saonara;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 9.75px;
    color: #d5cbb3;
    text-transform: uppercase;
    opacity: 1;
    font-weight: 300;
    line-height: 6.25vw;
    &:first-of-type {
      font-size: 5.208vw;
    }
    &:nth-of-type(2) {
      font-size: 7.682vw;
    }
    &:nth-of-type(3) {
      font-size: 9.766vw;
    }
  }
}
.about-model {
  max-width: 100%;
  height: auto;
  // padding-right: 4rem;
  &-border-first {
    border-style: solid;
    border-color: #fcf3ec;
    border: 100px;
  }
}

.about-content-wrapper {
  display: flex;
  padding-top: 2rem;
}
.custom-class {
  display: flex;
  flex-direction: row;
}
